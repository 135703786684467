import { Injectable } from  '@angular/core';
import { HttpClient } from  '@angular/common/http';

import { environment } from '../../environments/environment';

import { Storage } from  '@ionic/storage';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { Subject, Observable } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { LangPopoverPage } from '../components/lang-popover/lang-popover.page';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  BACKEND_URL:  string  =  environment.BACKEND_URL;
  token: string;
  private loading;
  private reportName: string;
  private lang_dir = new Subject<any>();
  private langObservable = new Subject<any>();
  private user = new Subject<any>();
  private menuData = new Subject<any>();
  private userId: number;
  private userRolLevel: number;
  private stationId: number;
  private TruckSelectedId: number;
  private stationName: string;
  public result: any;
  public lang = 1;
  private langs = [];
  private dataParams = [];
  private dataSubjects = [];



   constructor(
      private  httpClient:  HttpClient, 
      private  storage:  Storage, 
      public popoverController: PopoverController,    
      public alertController: AlertController,    
      public loadingController: LoadingController
     ) {
      this.storage.get("USER").then(user => {
        if(user) {
          this.setUser(user);
          this.setUserId(user.FldId);
          this.setUserRolLevel(user.FldRolLevel);
          this.stationId = user.FldStationID;
          //this.setStationId(user.FldStationID);
         }
      })
      this.storage.get("stationID").then(value => {
        if(value) {
          //console.log("service create", value);
          this.setStationId(value);
         }
      })
      this.storage.get("stationName").then(value => {
        if(value) {
          this.stationName = value;
         }
      })
      this.storage.get("languageID").then(value => {
        if(value) {
          this.lang = value;
         }
      })
      this.storage.get("TruckSelectedId").then(value => {
        if(value) {
          this.TruckSelectedId = value;
         }
      })

    }

    setDataParams(key, data) {
      this.dataParams[key] = data;
    }

    getDataParams(key) {
      return this.dataParams[key];
    }


    getDataSubject(key): Observable<any>{
      if(!this.dataSubjects[key]) {
        this.dataSubjects[key] = new Subject<any>();
      }
      return this.dataSubjects[key].asObservable();
    }

    setDataSubject(key, data) {
      if(!this.dataSubjects[key]) {
        this.dataSubjects[key] = new Subject<any>();
      }
      this.dataSubjects[key].next(data);
    }
    
    getMenuData():Observable<any>{
      return this.menuData.asObservable();
    }   

    setMenuData(data) {
      var _menu = [];
      // console.log(data);
      data.forEach(element => {
        var m =         {
          title: element['FldText'],
          url: (element['FldCmd'] && element['FldCmd'].indexOf(".") > 0) ? '/'+element['FldCmd'].substring(0, element['FldCmd'].indexOf(".")) : (element['FldCmd']) ? '/'+element['FldCmd'] : "",
          icon: 'layers',
          show: false,
          submenu: [],
          FldOrder: element['FldOrder'],
          isMobile: element['FldMobile']
        }          
        
        if(element['FldParentID']) {
          _menu[element['FldParentID']]['submenu'].push(m);
        } else {
          _menu[element['FldID']] = m
        }
        
      });
      
      var menu = [];
      // console.log(_menu);
      _menu.forEach(e => e['submenu'] = e['submenu'].sort((a,b) => a.FldOrder > b.FldOrder ? 1: -1) );
      _menu.forEach(e => menu.push(e));
      menu = menu.sort((a,b) => a.FldOrder > b.FldOrder ? 1: -1);
      
      this.menuData.next(menu);
    }
    
    setUser(user) {
      this.user.next(user);
    }


    getUser():Observable<any>{
      return this.user.asObservable();
    }       
    
    getUserId() {
      return this.userId;
    }       

    setUserId(id) {
      this.userId = id;
    }
    
    getUserRolLevel() {
      return this.userRolLevel;
    }       

    setUserRolLevel(id) {
      this.userRolLevel = id;
    }

    getStationId() {

       return this.stationId;
    }       

    getStationName() {
      return this.stationName;
    }       

    setStationId(id, name?) {
      //console.log("setStationId  in", id);
      if (this.stationId == id) return;
      this.stationId = id;
      //console.log("setStationId  out", id);
      this.storage.set('stationID', this.stationId);
      if(name) {
        this.stationName = name;
        this.storage.set('stationName', this.stationName);
      }
    }       

    
    setReportName(name) {
      this.reportName = name;
    }
    
    
    getReportName() {
      return this.reportName ;
    }


    setLangDir(dir) {
      this.lang_dir.next(dir);
    }
    
    async setLangId(l) {
      this.lang = l;
      await this.storage.set('languageID', this.lang);
    }

    async setTruckSelected(l) {
      this.TruckSelectedId = l;
      await this.storage.set('TruckSelectedId', this.TruckSelectedId);
    }

    async setLang(l) {
      this.lang = l;
      await this.storage.set('languageID', this.lang);
      this.langObservable.next(this.lang);
    }

    getLangDir():Observable<any>{
      return this.lang_dir.asObservable();
    }    

    getLangId() {
      return this.lang;
    }

    getTruckSelectedId() {
      return this.TruckSelectedId;
    }

    getLang():Observable<any>{
      return this.langObservable.asObservable();
    }    

    getLangs() {
      return this.langs;
    }

    setLangs(langs) {
      this.langs = langs;
    }

    getLocale() {
      switch(this.lang) {
        case 2: return 'he-IL'; break;
        default: return 'en-US'; 
      }
    }

   async getToken() {
     let me = this;
      return await this.storage.get("ACCESS_TOKEN").then(token => {
        if(token !== null){
          return token;
        }
        else
        {
          return false;
        }
      })
  }

  async presentAlertConfirm(header, message, okCallback, cancelCallback) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            if(cancelCallback)
              cancelCallback()            
          }
        }, {
          text: 'Ok',
          handler: () => {
            if(okCallback)
              okCallback()            
          }
        }
      ]
    });

    await alert.present();
  }    
  
  async presentAlert(header, message) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ok',
          handler: () => {}
        }
      ]
    });

    await alert.present();
  }    

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: LangPopoverPage,
      componentProps: {
        langs: this.getLangs()
      },
      event: ev,
      translucent: true      
    });

    popover.onDidDismiss().then((lang) => {
      if (lang !== null && lang.data) this.setLang(lang.data);
    });

    return await popover.present();

  }  

  async presentLoading() { 
     const loading = await this.loadingController.create({
      message: '',
      duration: 5 * 1000
    });  
    await loading.present();
    this.loading = loading;
  }  

  async dismissLoading() {
    if(this.loading) {
      await this.loading.dismiss();
    }
    this.loading = false;
  }  
  

  async getWithToken(params, url = "Getts") {
    return new Promise(resolve => {
      this.getToken().then(token => {
        if(typeof params == "undefined" || params == false) params = {params: {}}
        params.params.token = token;
        var a = this.httpClient.post(`${this.BACKEND_URL}/url`, params);
        this.dismissLoading()
        resolve(a)
      })
    })
    
  }

  printFields(arr) {
    var res = []
    arr.forEach((e) => res.push(e['FldObjectName']));

  }

  async postFields(params) {
    return new Promise(resolve => {
        if(typeof params == "undefined" || params == false) params = {params: {}}
        var a = this.httpClient.post(`${this.BACKEND_URL}/Getts`, params);
        a.subscribe(res => {
          var resd = JSON.parse(res['d']);
          // console.log(resd)
          // GET LANG
          if(resd && resd.result && typeof resd.result[0] != "undefined")
            this.setLangDir(resd.result[0].FldDirection)
          this.result = resd;
          
          let arrResult = resd.result1;
         

          this.printFields(arrResult);
          let result = [];
          arrResult.map(r => {
            result[r.FldObjectName.trim().replaceAll(/\s/g, "_")] = r
            // GET STATION LIST TEXTS
            if(r.FldObjectName == "Station_list") {
              this.setDataSubject("Station_list", r);
            }
          })

          if(typeof resd.result2 != "undefined") 
            this.langs = resd.result2;
          //this.dismissLoading()
          resolve(result)
        });
    })
    
  }
  
  async post(params) {
    return await new Promise(resolve => {
        if(typeof params == "undefined" || params == false) params = {params: {}}
        this.httpClient.post(`${this.BACKEND_URL}/Getts`, params).subscribe(res => {
          var resd = JSON.parse(res['d']);
          // console.log(params, JSON.parse(res['d']))
          resolve(JSON.parse(res['d']))
        });
    })
    
  }

  updateForm(ws: any, params: any) {
    //FldLanguageID, FldName, FldRolLevel
    /*
    params.push("FldLanguageId,"+this.lang)
    params.push("FldUserID,"+this.getUserId())
    params.push("FldRolLevel,"+this.getUserRolLevel())
    */
    params = params.join(";");
    return this.post({"Name": ws, "Params": params});
  }

  getMsg(msgID) {
    this.post({"Name": "UI_Language_msg", "Params": `PrmMsgID,${msgID};PrmLanguageID,${this.lang}`}).then(r => {
      if (r && r['result'] && r['result'][0]) this.setDataSubject("MsgID"+msgID, r['result'][0]['FldAlarmText']);
    })
  }

  getFields(key,params?: string) {
    if(params == undefined) params = "";
    let userParam = this.getUserId() ? "FldUserID,"+this.getUserId()+";" : "";
    return this.postFields({'Name': key, 'Params': params+userParam+'FldLanguageId,'+this.lang});
  }

  getWeightListData(stationID) {
    let userParam = this.getUserId() ? "FldUserID,"+this.getUserId()+";" : "";
    return this.post({'Name': 'UI_Weight_ListData', 'Params': userParam+ `FldStationID,${stationID};FldLanguageId,${this.lang}`});
  }

  getWeightOnlineData(stationID) {
    return this.post({'Name': 'UI_Weight_OnLine_Data', 'Params': `FldStationID,${stationID};FldLanguageId,${this.lang};FldTruckID,${this.TruckSelectedId};FldUserLogin,${this.userId}`});
  }
  
  getWeightItemReport(itemID) {
    return this.post({'Name': 'UI_WeighingCertificate', 'Params': `FldItemID,${itemID};FldLanguageId,${this.lang}`});
  }

  getStationData(stationID) {
    let userParam = this.getUserId() ? "FldUserID,"+this.getUserId()+";" : "";
    return this.post({'Name': 'UI_Station_Data', 'Params': userParam+'FldStationID,'+stationID+';FldLanguageId,'+this.lang});
  }

  getStationListData() {
    let userParam = this.getUserId() ? "FldUserID,"+this.getUserId()+";" : "";
    return this.post({'Name': 'UI_Station_ListData', 'Params': userParam+';FldLanguageId,'+this.lang});
  }

  getUsersData(stationID) {
    let userParam = this.getUserId() ? "FldUserID,"+this.getUserId()+";" : "";
    return this.post({'Name': 'UI_Users_Data', 'Params': userParam+'FldStationID,'+stationID+';FldLanguageId,'+this.lang});
  }

  getReportsData(stationID) {
    let userParam = this.getUserId() ? "FldUserID,"+this.getUserId()+";" : "";
    return this.post({'Name': 'UI_Reports_Data', 'Params': userParam+'FldStationID,'+stationID+';FldLanguageId,'+this.lang});
  }
 
 

  getTruckInSiteData(stationID) {
    let userParam = this.getUserId() ? "FldUserID,"+this.getUserId()+";" : "";
    return this.post({'Name': 'UI_TruckInSite_Data', 'Params': userParam+'FldStationID,'+stationID+';FldLanguageId,'+this.lang});
  }

  getGridData(name, stationID) {
    let userParam = this.getUserId() ? "FldUserID,"+this.getUserId()+";" : "";
    return this.post({'Name': `${name}`, 'Params': userParam+'FldStationID,'+stationID+';FldLanguageId,'+this.lang});
  }

  getUserStationData(stationID) {
    let userParam = this.getUserId() ? "FldUserID,"+this.getUserId()+";" : "";
    return this.post({'Name': 'UI_UserToStation', 'Params': userParam+'FldStationID,'+stationID+';FldLanguageId,'+this.lang});
  }


  dataSelect(name, params?: any, sendUser = true) {
    let userParam = (sendUser) ? this.getUserId() ? "FldUserID,"+this.getUserId()+";" : "" : "";
    var p = "";
    if(params) {
      p = params.join(";");
      p += ";";
    }
    return this.post({'Name': name, 'Params': p+userParam+'FldLanguageId,'+this.lang});
  }

  doLogin(user) {
    return this.httpClient.post(`${this.BACKEND_URL}/Getts`, {'Name': 'UI_login', 'Params':  `FldLoginName,${user.email};FldPassowrd,${user.password};FldLanguageId,${this.lang}`})
  }

  doForgot(data) {
    return this.httpClient.post(`${this.BACKEND_URL}/ForgetPassword`, {'Params':  `Email,${data.email};FldLanguageId,${this.lang}`})
  }

  doChangePassword(data) {
    let id = this.getUserId();
    return this.httpClient.post(`${this.BACKEND_URL}/Getts`, {'Name': 'UI_SetPassword','Params': `FldId,${id};FldOldPassword,${data.OldPassword};FldNewPassword,${data.NewPassword};FldLanguageId,${this.lang}`})
  }

  saveWeight(data) {
    return this.post({'Name': 'UI_Weight_Save', 'Params': data });

  }

  /*
  async getSolicitudes(page, per_page) {
    return this.getWithToken("/solicitudes",  {params: {"page":page, "per_page": per_page }})
  }
  */

 dateToStr(d) {
  if(!d) return "";
  var res = d.match(/\/Date\(([0-9]+)\)/);
  if(!res) return d;
  var date = new Date(parseInt(res[1]));
  var day = date.getDate();
  var month =  date.getMonth()+1;
  var year = date.getFullYear();

  var formattedTime = day + '/' + month + '/' + year;
  return typeof formattedTime != "undefined" ? formattedTime : "";
}   
 
dateToDBStr(d) {
  if(!d) return "";
  var res = d.match(/\/Date\(([0-9]+)\)/);
  if(!res) return d;
  var date = new Date(parseInt(res[1]));
  var day = date.getDate();
  var month =  date.getMonth()+1;
  var year = date.getFullYear();

  var formattedTime = year + '-' + month + '-' + day;
  return typeof formattedTime != "undefined" ? formattedTime : "";
}   

dateToDateObj(d) {
  if(!d) return "";
  var res = d.match(/\/Date\(([0-9]+)\)/);
  if(!res) return d;
  var date = new Date(parseInt(res[1]));
  return date;
}   



  submitToken(user) {
    const data = {'Name': 'UI_LoginByToken', 'Params':  `FldLoginName,${user.email};FldToken,${user.token};FldLanguageId,${this.lang ? this.lang : 1}`}
    // console.log(data)
    // return false;
    return this.httpClient.post(`${this.BACKEND_URL}/Getts`, data)
  }



}
