// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/* for testing*/
export const environment = {
  production: false,
  WEIGHT_ONLINE_DATA_TTL: 1000,
  BACKEND_URL: "https://scalenet.arbicom.co.il/webservice/ZahService.asmx",
  IMAGES_URL: "https://scalenet.arbicom.co.il/webservice/Images/Logos",
  LPR_URL: "https://scalenet.arbicom.co.il/webservice/LPR",
  LPR_APPROVED_URL: "https://scalenet.arbicom.co.il/webservice/LPRApproved",
  UPLOAD_URL: "https://scalenet.arbicom.co.il/webservice/FileUpload.aspx",
  REPORT_URL: "https://scalenet.arbicom.co.il/webservice/",//WeighingCertificate.aspx
  REPORTS_URL: "https://scalenet.arbicom.co.il/webservice/Reports.aspx"
}


/*
export const environment = {
production: false,
  WEIGHT_ONLINE_DATA_TTL: 1000,
  BACKEND_URL: "http://scaleweb.arbicom.co.il:1054/webservice/ZahService.asmx",
  IMAGES_URL: "http://scaleweb.arbicom.co.il:1054/webservice/Images/Logos",
  LPR_URL: "http://scaleweb.arbicom.co.il:1054/webservice/LPR",
  LPR_APPROVED_URL: "https://scalenet.arbicom.co.il/webservice/LPRApproved",
  UPLOAD_URL: "http://scaleweb.arbicom.co.il:1054/webservice/FileUpload.aspx",
  REPORT_URL: "http://scaleweb.arbicom.co.il:1054/webservice/",
  REPORTS_URL: "http://scaleweb.arbicom.co.il:1054/webservice/Reports.aspx"

}
*/
/* For Rpi
  BACKEND_URL: "http://127.0.0.1:5000/ZahService.asmx",
  IMAGES_URL: "http://127.0.0.1:5000/Images/Logos",
  UPLOAD_URL: "http://scaleweb.arbicom.co.il:1054/FileUpload.aspx"



*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
