import { Component, OnInit, enableProdMode,  } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { MenuController } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Storage } from  '@ionic/storage';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { BackendService } from './services/backend.service';
import { UserModel } from './models/user';
import { MainModel } from './models/main';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActionSheetController } from '@ionic/angular';
import { HttpClient, HttpHeaders ,HttpErrorResponse } from "@angular/common/http";
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = []
  public user = null;
  public dir = "ltr" 
  public userModel: UserModel = new UserModel(); 
  public mainModel: MainModel = new MainModel(); 
  public welcomeText: string;
  public logoutText: string;
  public helpText: string;
  public stationListText = { FldLanguageId: 1,FldObjectName: "Station_list",FldRolLevel: 0,FldText: ""}
  public stationData: any = [];
  public todo : UntypedFormGroup;
  public reloadOnStationChange = true;
  public deferredPrompt: any;
  private config: {version: string};

  

  constructor(
    private  backendService:  BackendService,
    private  authService:  AuthService,
    private translateService: TranslateService,
    private  storage:  Storage,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public actionSheetController: ActionSheetController,
    private menu: MenuController,
    private httpClient: HttpClient,
    private http: HttpClient
   
  ) {
    this.removeConsole();
    this.initializeApp();
    this.backendService.getLangDir().subscribe(dir => this.dir = dir);
    this.backendService.getMenuData().subscribe(data => this.appPages = data);
    this.backendService.getUser().subscribe(user => this.userModel = user as UserModel);
    this.backendService.getDataSubject("welcomeText").subscribe(data => this.welcomeText = data);
    this.backendService.getDataSubject("logoutText").subscribe(data => this.logoutText = data);
    this.backendService.getDataSubject("helpText").subscribe(data => this.helpText = data);
    this.backendService.getDataSubject("Station_list").subscribe(data => this.mainModel = {"Station_list": data} as MainModel);
    this.backendService.getDataSubject("Station_list_data").subscribe(data => this.todo.controls['PrmStationID'].setValue(this.backendService.getStationId()));
    this.todo = this.formBuilder.group({
      PrmStationID: new UntypedFormControl()
    })
  }

  removeConsole() {
    if (environment.production) {
      
      // HACK: Don't log to console in production environment.
      // TODO: This can be done in better way using logger service and logger factory.
      // console.log(window)
      if(window){
        window.console.log = window.console.warn = window.console.info = function(){
          // Don't log anything.
        };
      }
    }    
  }
  
  initializeApp() {
    /*
    this.platform.ready().then(() => {
      this.translateService.setDefaultLang('es');
      this.translateService.use('es');
      this.statusBar.styleDefault();
      //this.splashScreen.hide();
    });
    */


     window.addEventListener('beforeinstallprompt', (e) => {
      //  console.log('beforeinstallprompt Event fired');
       // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });

    window.addEventListener('appinstalled', (event) => {
      alert('installed');
    });    


}

  ionViewWillEnter() {


  }
  
  showInstallBanner() {
    // console.log(this.deferredPrompt);
    if (this.deferredPrompt !== undefined && this.deferredPrompt !== null) {
      // Show the prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // console.log('User accepted the A2HS prompt');
        } else {
          // console.log('User dismissed the A2HS prompt');
        }
        // We no longer need the prompt.  Clear it up.
        this.deferredPrompt = null;
      });
    }
  }


  changeOfRoutes() {
    this.storage.get("USER").then(user => {
      this.user = user
      if(user != null) {
        this.backendService.setUser(user);
        this.backendService.getStationListData().then(res => {
          this.stationData = res;
          if(this.backendService.getStationId()) {
            this.reloadOnStationChange = false;
            this.backendService.setDataSubject("Station_list_data", this.backendService.getStationId());
            //this.todo.controls['PrmStationID'].setValue(this.backendService.getStationId());    
          }
        })
      }
    })    
  }

  selectIndex() {
    const path = window.location.pathname;
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.url == path);
    }
    if(this.selectedIndex < 0) this.selectedIndex = 0;
  }

  ngOnInit() {
    //console.log("onInit")
    this.selectIndex()

    this.backendService.getLang().subscribe(dir => window.location.reload());
    //this.presentActionSheet();

    // Force clear cache implementation
     this.httpClient.get("./../assets/config.json")
      .subscribe((res: any) => {
        this.config = res;
        const headers = new HttpHeaders()
          .set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache');
          
        this.httpClient
          .get<{ version: string }>("/assets/config.json", {headers})
          .subscribe(config => {
            if (config.version !== this.config.version) {
              this.httpClient
                .get("", { headers, responseType: "text" })
                .subscribe(() => location.reload());
            }
          });
      });

    

  }

  

  async presentActionSheet() {
    var me = this;
    
    const actionSheet = await this.actionSheetController.create({
      header: "header",
      cssClass: 'my-custom-class',
      buttons:[{
        text: "Add to HomeScreen",
        handler: () => {
          this.showInstallBanner();
        }
      }]
    });
    await actionSheet.present();
  }    

  
  help(){
    
    const url = 'https://scalenet.arbicom.co.il/help.pdf';
    
   // this.http.get(url).subscribe((data) => {
    //  const blob = new Blob([data], { type: 'application/pdf' });
     // const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = "";
      link.target = "_blank";
      link.href = url;
      
      //link.download = 'file.pdf';
      link.click();
    //});

  
      
     
    }
  



  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/login');

  }

  menuClose(p:any) {
    if(p.submenu.length ==0 ) {
      this.router.navigate([p.url]);
      this.menu.close();
    }
  }

  onStationChange(e) {
    //debugger;
    // console.log("onStationChange", e)
    var stationID = e;
    //if(stationID == this.todo.controls['PrmStationID']) return;
    var stations = this.stationData['result'];
    var arrStations = stations.length > 0 ? stations.filter(s => s.FldID == e) : [];
    var stationName = "";
    if(arrStations && typeof arrStations[0] != "undefined") {
      stationName = arrStations[0]['FldStationName'];
    }
    this.backendService.setStationId(stationID, stationName);
    //debugger;
    if(arrStations[0]['FldReportName']){
      this.backendService.setReportName(arrStations[0]['FldReportName']);
    }
    else{
      this.backendService.setReportName("WeighingCertificate.aspx");
    }
    if(this.reloadOnStationChange) {
      // Delay the reload for 1 second (1000 milliseconds)
      setTimeout(() => {
        window.location.reload();
        }, 1000);
    // Adjust the delay time as needed
      
    }
    this.reloadOnStationChange = true;
  }  


}
